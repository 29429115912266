import React from "react";
import _ from "lodash";
import moment from "moment-strftime";

import { Layout } from "../components/index";
import { getPages, Link, safePrefix } from "../utils";
import styled from "styled-components";

import PodcastSubscribe from "../components/PodcastSubscribe";

import logo from "../images/icons/em-logo.svg";

const HeaderWrapper = styled.section`
  text-align: center;
  padding: 70px 20px;

  p {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const EMLogo = styled.img`
  width: 80%;
  max-width: 300px;
  margin-bottom: 20px;
`;

const PodCard = styled(Link)`
  border: 1px solid #2456ea;
  border-bottom-width: 0;
  padding: 25px;

  &:last-child {
    border-bottom-width: 1px;
  }
`;

const PodFeed = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 770px;
  margin: 0 20px 30px;

  @media (min-width: 768px) {
    margin: 0 20px calc(10vh + 20px);
  }

  @media (min-width: 1024px) {
    margin: 0 auto calc(10vh + 100px);
  }

  p {
    color: #ff6367;
    margin-bottom: 0;
  }
`;

export default class Blog extends React.Component {
  render() {
    let all_posts = _.orderBy(
      getPages(this.props.pageContext.pages, "/podcast"),
      "frontmatter.date",
      "desc"
    );
    let display_posts = _.drop(all_posts);
    _.map(display_posts, (post) => {
      let postDate = Date.parse(_.get(post, "frontmatter.date"));
      let currentTime = Date.now();

      if (postDate > currentTime) {
        _.remove(display_posts, post);
      }
    });

    return (
      <Layout {...this.props}>
        <HeaderWrapper>
          <EMLogo src={logo} alt="Executive Minds" />
          <p>
            The Executive Minds Podcast helps go-getters, like you, discover
            what it takes to turn good intentions into reality in their career,
            business, and life.
          </p>
          <PodcastSubscribe />
        </HeaderWrapper>
        <PodFeed>
          {_.map(all_posts, (post, post_idx) => {
            let postDate = Date.parse(_.get(post, "frontmatter.date"));
            let currentTime = Date.now();

            if (postDate < currentTime)
              return (
                <PodCard key={post_idx} to={safePrefix(_.get(post, "url"))}>
                  <h2 className="post-title">
                    {_.get(post, "frontmatter.title")}
                  </h2>
                  <time
                    className="published"
                    dateTime={moment(_.get(post, "frontmatter.date")).strftime(
                      "%Y-%m-%d %H:%M"
                    )}
                  >
                    {moment(_.get(post, "frontmatter.date")).strftime(
                      "%B %d, %Y"
                    )}
                  </time>
                  <p>Listen Now</p>
                </PodCard>
              );
          })}
        </PodFeed>
      </Layout>
    );
  }
}
